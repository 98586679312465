<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

<div class="p-grid">
    <div class="p-col-12">
        <h2>Ciclo de vida (LCI)</h2>
    </div>

    <!-- Tab View -->
    <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Mapa de proceso">
            <div class="w-full flex align-items-center justify-content-center">
                En desarrollo...
            </div>
        </p-tabPanel>
        <p-tabPanel header="Inventario">
            <div class="card basicCard">
                <div class="container-title">
                    <h3>Materia prima</h3>
                </div>
            
                <!-- Inventario -->
                <div class="container-title">
                    <h4>Inventario</h4>
                    <div class="table-container p-col-12">
                        <p-table 
                          #dt1
                          [value]="rawMaterials" 
                          [paginator]="true" 
                          [rows]="3"
                          dataKey="id"
                          [rowHover]="true"
                          [globalFilterFields]="['name']"
                          styleClass="p-datatable-striped"
                          responsiveLayout="scroll"
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Cantidad</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>
                                        {{ product?.productSelected?.name }}
                                    </td>
                                    <td>
                                        {{ product?.quantity }} {{ product?.unit }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">No se encontraron datos</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
        
                <!-- Añadir item -->
                <div class="btn-container">
                    <p-button 
                        label="Añadir item" 
                        icon="pi pi-plus" 
                        (click)="addItem(1)"
                    />
                </div>
            </div>
        
            <div class="card basicCard">
                <div class="container-title">
                    <h3>Producción</h3>
                </div>
        
                <!-- Inventario -->
                <div class="container-title">
                    <h4>Inventario</h4>
                    <div class="table-container p-col-12">
                        <p-table 
                          #dt1
                          [value]="production" 
                          [paginator]="true" 
                          [rows]="3"
                          dataKey="id"
                          [rowHover]="true"
                          [globalFilterFields]="['name']"
                          styleClass="p-datatable-striped"
                          responsiveLayout="scroll"
                        >        
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Nombre</th>
                                    <th>Cantidad</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>
                                        {{ product?.productSelected?.name }}
                                    </td>
                                    <td>
                                        {{ product?.quantity }} {{ product?.unit }}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">No se encontraron datos</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
        
                <!-- Añadir item -->
                <div class="btn-container">
                    <p-button 
                        label="Añadir item" 
                        icon="pi pi-plus" 
                        (click)="addItem(2)"
                    />
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Análisis">
            <div class="w-full flex align-items-center justify-content-center">
                En desarrollo...
            </div>
        </p-tabPanel>
    </p-tabView>
</div>

<!-- Modal añadir items a inventario de materia prima -->
<p-dialog
    header="Añadir item a inventario de materia prima"
    [(visible)]="dialogRawMaterial"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    (onHide)="closeDialogRawMaterials()"
>
    <div class="w-full p-4">
        <form [formGroup]="rawMaterialsForm">
            <ion-grid>
                <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12">   
                        <label for="">Producto</label>
                        <p-dropdown                 
                            [options]="products"
                            appendTo="body"
                            class="w-full"
                            optionLabel="name"
                            [style]="{ width: '100%' }"
                            [inputStyle]="{ width: '100%' }"
                            placeholder="Seleccione un producto"
                            formControlName="productSelected"
                        ></p-dropdown>
                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6">
                        <label for="">Cantidad (kg)</label>
                        <p-inputNumber 
                            formControlName="quantity" 
                            inputId="minmaxfraction" 
                            mode="decimal" 
                            [style]="{ width: '100%' }"
                            [inputStyle]="{ width: '100%' }"                            
                            class="w-full"
                            [maxFractionDigits]="6" 
                        /> 
                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6">
                        <label for="">Unidad</label>
                        <input 
                            class="w-full"
                            pInputText
                            [style]="{ width: '100%' }"
                            formControlName="unit"
                        /> 
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </div>

    <ng-template pTemplate="footer">
        <p-button label="Guardar" (click)="onSubmit(1)">
        </p-button>
    </ng-template>
</p-dialog>

<!-- Modal añadir items a inventario de producción -->
<p-dialog
    header="Añadir item a inventario de producción"
    [(visible)]="dialogProduction"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    (onHide)="closeDialogProduction()"
>
    <div class="w-full p-4">
        <form [formGroup]="productionForm">
            <ion-grid>
                <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12">   
                        <label for="">Producto</label>   
                        <p-dropdown                 
                            [options]="products"
                            appendTo="body"
                            class="w-full"
                            optionLabel="name"
                            [style]="{ width: '100%' }"
                            [inputStyle]="{ width: '100%' }"
                            placeholder="Seleccione un producto"
                            formControlName="productSelected"
                        ></p-dropdown>
                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6">
                        <label for="">Cantidad (kg)</label>
                        <p-inputNumber 
                            formControlName="quantity" 
                            inputId="minmaxfraction" 
                            mode="decimal" 
                            [style]="{ width: '100%' }"
                            [inputStyle]="{ width: '100%' }"                            
                            class="w-full"
                            [maxFractionDigits]="6" 
                        /> 
                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="6">
                        <label for="">Unidad</label>
                        <input 
                            class="w-full"
                            pInputText
                            [style]="{ width: '100%' }"
                            formControlName="unit"
                        /> 
                    </ion-col>
                </ion-row>
            </ion-grid>
        </form>
    </div>

    <ng-template pTemplate="footer">
        <p-button 
            label="Guardar" 
            (click)="onSubmit(2)"
        ></p-button>
    </ng-template>
</p-dialog>