import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Company } from 'src/app/models/company';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/user.service';
import { ApiService } from 'src/app/services/api.service';
import { concatMap, Observable, of } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  userCompanies: any[] = [];
  user: User;
  loading: boolean = true;
  responsiveOptions: any[] | undefined;
  companiesByUser: any[] = [];

  constructor(
    private userService: UserService,
    private router: Router,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.getActualUser();

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }

  /**
   * Obtener datos del usuario actual en sesión
   */
  getActualUser() {
    this.apiService.getData('/api/user').subscribe({
      next: (response) => {
        this.user = response;
        this.getCompanies();
      },
      error: (err) => {
        console.error('Error obteniendo datos del usuario actual: ', err);
      },
    });
  }

  /**
   * Ir a ESG con token de sesión del usuario actual
   */
  goTo(companyId) {
    const token = this.userService.getToken();
    const moduleUrl = environment.esrsURL + '#/panel';

    if (moduleUrl.startsWith('http://') || moduleUrl.startsWith('https://')) {
      // Si la URL comienza con 'http://' o 'https://', es una URL externa
      const tokenizedUrl =
        moduleUrl +
        (moduleUrl.includes('?') ? '&' : '?') +
        'token=' +
        token +
        '&' +
        'companyId=' +
        companyId;
      window.location.href = tokenizedUrl;
    } else {
      // Ruta interna
      this.router.navigateByUrl(moduleUrl);
    }
  }

  /**
   * Obtener listado de empresas registradas de un
   */
  getCompanies() {
    this.apiService
      .getData(`/api/users/${this.user.id}`)
      .pipe(
        concatMap((response) => {
          const user = response.data;
          // Me aseguro que la segunda llamada se hace si y solo si la primera acaba con concatMap
          return this.getCompaniesByUser(user);
        })
      )
      .subscribe({
        next: (response) => {
          // Employees, suppliers y customers separados
          this.companiesByUser = response;
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.error('Error al obtener los datos:', err);
        },
      });
  }

  getCompaniesByUser(user: any): Observable<any> {
    // Obtener las empresas de employees
    const employeeCompanies = user.employees.map(
      (employee: any) => employee.department.companies
    );
    const uniqueEmployeeCompanies = this.getUniqueCompanies(employeeCompanies);

    // Obtener las empresas de customers
    const customerCompanies = user.customers.map(
      (customer: any) => customer.company
    );
    const uniqueCustomerCompanies = this.getUniqueCompanies(customerCompanies);

    // Obtener las empresas de suppliers
    const supplierCompanies = user.suppliers.map(
      (supplier: any) => supplier.company
    );
    const uniqueSupplierCompanies = this.getUniqueCompanies(supplierCompanies);

    //Obtener las empresas asociadas sin rol
    const companies = user.companies.map((item) => item);
    const uniqueCompanies = this.getUniqueCompanies(companies);

    // Retornar las empresas separadas por tipo
    return of({
      employees: uniqueEmployeeCompanies,
      customers: uniqueCustomerCompanies,
      suppliers: uniqueSupplierCompanies,
      companies: uniqueCompanies,
    });
  }

  getUniqueCompanies(companies: any[]): any[] {
    return Array.from(new Set(companies.map((company) => company.id))).map(
      (id) => companies.find((company) => company.id === id)
    );
  }
}
