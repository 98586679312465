@if (loading) {
<div class="spinner-container">
  <ion-spinner name="dots"></ion-spinner>
</div>
}
<!--  -->
@if (!loading && userCompanies) {
<ion-grid>
  <ion-row
    [ngClass]="{'single-row': (companiesByUser['companies'].length === 1 && companiesByUser['employees'].length === 0) || (companiesByUser['companies'].length === 0 && companiesByUser['employees'].length === 1)}"
  >
    @if(companiesByUser['companies'].length > 0){
    <ion-col size="12" size-md="6">
      <div class="card flex justify-content-center">
        <p-carousel
          [value]="companiesByUser['companies']"
          [numVisible]="1"
          [numScroll]="1"
          orientation="vertical"
          verticalViewPortHeight="400px"
        >
          <ng-template pTemplate="header">
            <h5>Empresas donde eres asociado</h5>
          </ng-template>
          <ng-template let-product pTemplate="item">
            <div class="border-1 surface-border border-round m-2 p-3 h-50">
              <div class="mb-3">
                <div class="flex align-items-center justify-content-center">
                  @defer() {
                  <img
                    src="assets/images/logo-default.png"
                    style="width: 25rem"
                    class="border-round"
                  />
                  }
                </div>
              </div>
              <div class="mb-3">
                <h5>{{ product.company_name }}</h5>
              </div>
              <div class="flex justify-content-center align-items-center">
                <p-button
                  label="Ver detalles"
                  (click)="goTo(product.id)"
                  class="primary"
                />
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </ion-col>
    } @if(companiesByUser['employees'].length > 0){
    <ion-col size="12" size-md="6">
      <div class="card flex justify-content-center">
        <p-carousel
          [value]="companiesByUser['employees']"
          [numVisible]="1"
          [numScroll]="1"
          orientation="vertical"
          verticalViewPortHeight="550px"
        >
          <ng-template pTemplate="header">
            <h5>Empresas donde eres empleado</h5>
          </ng-template>
          <ng-template let-product pTemplate="item" >
            <div class="border-1 surface-border border-round m-2 p-3">
              <div class="mb-3">
                <div class="flex align-items-center justify-content-center">
                  @defer() {
                  <img
                    src="assets/logos/mercadona_integration.png"
                    style="width: 25rem"
                    class="border-round"
                  />
                  }
                </div>
              </div>
              <div class="mb-3 font-medium">
                <h5>{{ product.company_name }}</h5>
              </div>
              <div class="flex justify-content-center align-items-center">
                <p-button label="Ver detalles" (click)="goTo(product.id)" />
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </ion-col>
    }
  </ion-row>

  <ion-row
    [ngClass]="{'single-row': (companiesByUser['customers'].length === 1 && companiesByUser['suppliers'].length === 0) || (companiesByUser['customers'].length === 0 && companiesByUser['suppliers'].length === 1)}"
  >
    @if(companiesByUser['customers'].length > 0){
    <ion-col size="12" size-md="6">
      <div class="card flex justify-content-center">
        <p-carousel
          [value]="companiesByUser['customers']"
          [numVisible]="1"
          [numScroll]="1"
          orientation="vertical"
          verticalViewPortHeight="400px"
        >
          <ng-template pTemplate="header">
            <h5>Empresas donde eres cliente</h5>
          </ng-template>
          <ng-template let-product pTemplate="item">
            <div class="border-1 surface-border border-round m-2 p-3">
              <div class="mb-3">
                <div class="flex align-items-center justify-content-center">
                  @defer() {
                  <img
                    src="assets/logos/mercadona_integration.png"
                    style="width: 25rem"
                    class="border-round"
                  />
                  }
                </div>
              </div>
              <div class="mb-3">
                <h5>{{ product.company_name }}</h5>
              </div>
              <div class="flex justify-content-center align-items-center">
                <p-button label="Ver detalles" (click)="goTo(product.id)" />
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </ion-col>
    } @if(companiesByUser['suppliers'].length > 0){
    <ion-col size="12" size-md="6">
      <div class="card flex justify-content-center">
        <p-carousel
          [value]="companiesByUser['suppliers']"
          [numVisible]="1"
          [numScroll]="1"
          orientation="vertical"
          verticalViewPortHeight="400px"
        >
          <ng-template pTemplate="header">
            <h5>Empresas donde eres proveedor</h5>
          </ng-template>
          <ng-template let-product pTemplate="item">
            <div class="border-1 surface-border border-round m-2 p-3">
              <div class="mb-3">
                <div class="flex align-items-center justify-content-center">
                  @defer() {
                  <img
                    src="assets/logos/mercadona_integration.png"
                    style="width: 25rem"
                    class="border-round"
                  />
                  }
                </div>
              </div>
              <div class="mb-3">
                <h5>{{ product.company_name }}</h5>
              </div>
              <div class="flex justify-content-center align-items-center">
                <p-button label="Ver detalles" (click)="goTo(product.id)" />
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </ion-col>
    }
  </ion-row>
</ion-grid>
}
